@charset "utf-8";

@font-face {
    font-family: 'Cafe24Ssurround';
    font-style: normal;
    font-weight: 400;
    src: local('Cafe24Ssurround'), url('../fonts/Cafe24Ssurround.ttf') format('truetype');
}
@font-face {
    font-family: 'BagelFatOne';
    font-style: normal;
    font-weight: 400;
    src: local('BagelFatOne-Regular'), url('../fonts/BagelFatOne-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 100;
    src: local('Pretendard-Light'), url('../fonts/Pretendard-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    src: local('Pretendard-Regular'), url('../fonts/Pretendard-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    src: local('Pretendard-Medium'), url('../fonts/Pretendard-Medium.ttf') format('truetype');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    src: local('Pretendard-Bold'), url('../fonts/Pretendard-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 900;
    src: local('Pretendard-ExtraBold'), url('../fonts/Pretendard-ExtraBold.ttf') format('truetype');
}
@font-face {
    font-family: 'LOTTERIACHAB';
    font-style: normal;
    font-weight: normal;
    src: local('LOTTERIACHAB'), url('../fonts/LOTTERIACHAB.ttf') format('truetype');
}

html,.body{height: auto;scroll-behavior: smooth;}
.body {box-sizing: border-box;/*overflow-x: hidden;*/line-height: 1.3;}
.body *{box-sizing: border-box;}
.body,.body input,.body select,.body textarea,.body table{
	font-family:'Pretendard','Noto Sans KR','맑은 고딕', 'Malgun Gothic', sans-serif;
	font-size: 16px;
	letter-spacing: -0.025em;
   	color: #222;
}
.body button{font-family:'Pretendard','Noto Sans KR','맑은 고딕', 'Malgun Gothic', sans-serif;letter-spacing: -0.025em;}
#wrap{width:100%;text-align:left; min-width:320px;/*overflow: hidden;*/}

/* 사용자 공통변수 */
:root {
    --point-color1: #ED2452;
    --point-color2: #F9FEFF;
    --point-color3: #F85C83;
    --point-color4: var(--admin-color5);
}



/************************* 공통 *****************************/

body,button,input,select,textarea,table {
	font-family: 'Noto Sans KR','맑은 고딕','malgun gothic',sans-serif;
	font-size: 16px;
	word-break: keep-all;
	word-wrap: break-word;
	letter-spacing: -0.05em;
}
.flex{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.flex_end{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex; -webkit-box-pack: end; -ms-flex-pack: end;-webkit-justify-content: flex-end; justify-content: flex-end;}
.flex_between{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-between; justify-content: space-between;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.flex_center{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;}
.flex_wrap{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;-ms-flex-wrap: wrap;flex-wrap: wrap;}
.flex_top{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: start; -ms-flex-align: start;-webkit-align-items: flex-start; align-items: flex-start;}
.flex_bottom{display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-align: end; -ms-flex-align: end;-webkit-align-items: flex-end; align-items: flex-end;}
.clearfix:after,.clearfix2:after{content:''; display:block; clear:both;}
.clearfix>*,.clearfix2>*{float:left;}
.clearfix2>*:nth-child(2){float: right;}
.ellipsis {display: block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;width:100%;}
.ellipsis2 {overflow: hidden; text-overflow: ellipsis;display: -webkit-box; -webkit-line-clamp: 2;-webkit-box-orient: vertical; word-wrap:break-word;}
.ellipsis4 {overflow: hidden; text-overflow: ellipsis;display: -webkit-box; -webkit-line-clamp: 4;-webkit-box-orient: vertical; word-wrap:break-word;}
.underline{text-decoration: underline;}
.mo_show{display: none;}

/* 페이징 */
.paging {text-align: center; position: relative; padding-top: 30px; display: flex; justify-content: center;}
.paging button {width: 30px; height: 30px; line-height: 30px; border: 1px solid transparent; display: inline-block; vertical-align: top; margin: 0 6px; font-size: 18px; color: #000;}
.paging button.on {border-radius: 8px; background: var(--point-color1);color: #fff;}
.paging .btn_paging {text-indent: -9999px; text-align: left; position: relative;}
.paging .btn_paging:after {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: url('../images/arrow_paging.svg') no-repeat 50% 50%;}
.paging .btn_paging.btn_prev {margin: 0 10px 0 0;}
.paging .btn_paging.btn_next {margin: 0 0 0 10px;}
.paging .btn_paging.btn_next:after {transform: rotateZ(180deg);}
.paging .btn_paging.btn_prev2,.paging .btn_paging.btn_next2{margin: 0;}
.paging .btn_paging.btn_prev2::after,.paging .btn_paging.btn_next2::after{background: url('../images/arrow_paging2.svg') no-repeat 50% 50%;}

/* animation */
@keyframes aniUp {
    0% { opacity: 0; transform: translateY(40%);}
    100% { opacity: 1; transform: translateY(0);}
}
@keyframes aniDown {
    0% { opacity: 0; transform: translateY(-40%);}
    100% { opacity: 1; transform: translateY(0);}
}
@keyframes aniRight {
    0% { opacity: 0; transform: translateX(-40%);}
    100% { opacity: 1; transform: translateX(0);}
}
@keyframes aniLeft {
    0% { opacity: 0; transform: translateX(40%);}
    100% { opacity: 1; transform: translateX(0);}
}
@keyframes aniShow {
    0% { opacity: 0;}
    100% { opacity: 1;} 
}

/* 인풋 스타일 */
.input_box {position: relative; text-align: left;}
.input_box input {width: 100%; height: 100%; border: 1px solid #ddd; border-radius: 8px; padding: 0 20px; color: #222; font-size: 18px; position: relative; height: 56px; transition: all .3s;}
.input_box input:focus {border-color: var(--point-color1);}
.input_box input:disabled {background: #f6f6f6; border-color: #f6f6f6; color: #222;}
.input_box.pass_input input{padding-right: 44px;}
.input_box.pass_input .btn_view{position: absolute;width: 44px;height: 100%;background: url(../images/ic_eye.svg) no-repeat 0% 50%;right: 0;top: 0;font-size: 0;text-indent: -9999px;}

/* select 스타일 */
.select_style1 {width: 100%; position: relative;}
.select_style1 select {width: 100%; display: block; padding: 0 24px; font-size: 18px; color: #222; background: none; border: 1px solid #ddd; border-radius: 8px; height: 56px; position: relative; z-index: 1; transition: all .3s;}
.select_style1:after {content: ''; position: absolute; right: 24px; top: 50%; transform: translateY(-50%); width: 24px; height: 24px; background: url('../images/ic_select_arrow01.svg') no-repeat 50% 50%/12px;z-index: 1;}
.select_style1 select:focus {border-color: var(--point-color1);}

/* 버튼 스타일 */
.btn_style1 {display: inline-block; vertical-align: top; border-radius: 8px; border: 1px solid var(--point-color1); background: var(--point-color1); text-align: center; font-size: 18px; font-weight: 500; color: #fff; line-height: 28px; padding: 15px 0; cursor: pointer;}
.btn_type1 {border: 2px solid #000; border-radius: 60px; background: var(--point-color1); text-align: center; box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.25); transform: translateY(0);}
.btn_type2 {border-radius: 60px; background: #000; text-align: center; box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.25); transform: translateY(0);}
.btn_type1 a,.btn_type2 a {display: block; font-size: 20px; font-weight: 700; color: #fff; line-height: 30px; padding: 15px 0;}
.btn{font-size: 20px;font-weight: 700;color: #fff;background: #000;border-radius: 5px;height: 56px;}
.btn2{font-size: 20px;font-weight: 700;color: #000;background: #fff;border-radius: 5px;height: 56px;border: 1px solid #000;}
.btn3{font-size: 20px;font-weight: 700;color: #666;background: #fff;border-radius: 5px;height: 56px;border: 1px solid #ddd;}

/* textarea */
.textarea_box {border: none;position: relative;padding-bottom: 48px;min-height: 260px;}
.textarea_box textarea {height: 100%; border: 1px solid #ddd; border-radius: 8px; padding: 15px 20px; transition: all .3s;font-size: 18px;position: absolute;top: 0;left: 0;}
.textarea_box textarea:focus {border-color: var(--point-color1);}
.textarea_box .count{position: absolute;font-size: 14px;font-weight: 500;color: #999;right: 20px;bottom: 16px;}
.textarea_box .count span{color: #666;}

/* 성별 */
.chk_gender input[type="radio"] + label {display: block; text-align: center; position: relative; cursor: pointer;}
.chk_gender input[type="radio"] + label:before {content: ''; position: absolute; left: 22px; top: 22px; width: 24px; height: 24px; border: 1px solid #ddd; background: transparent; box-sizing: border-box; border-radius: 100%; transition: all .2s;}
.chk_gender input[type="radio"] + label:after {content: ''; position: absolute; left: 30px; top: 26px; width: 5px; height: 10px; border: solid #fff; border-width: 0 3px 3px 0; transform: rotate(45deg); transition: all .2s;}
.chk_gender input[type="radio"]:checked + label:before {border-color: var(--point-color1); background: var(--point-color1);}
.chk_gender input[type="radio"]:checked + label:after {opacity: 1;}
.chk_gender input[type="radio"] + label span {display: block; position: relative; padding: 26px 0 20px; font-size: 18px; font-weight: 500; color: #000;}
.chk_gender input[type="radio"] + label span:before {content: ''; display: block; margin: 0 auto; width: 56px; height: 56px; background: url('../images/ic_man.svg') no-repeat 50% 50%/100%;}
.chk_gender.chk_gender_woman input[type="radio"] + label span:before {background-image: url('../images/ic_woman.svg');}
.chk_gender input[type="radio"] + label span:after {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; box-sizing: border-box; border: 2px solid #ddd; border-radius: 8px; transition: all .3s;}
.chk_gender input[type="radio"]:checked + label span:after {border-color: var(--point-color1);}

/* 체크박스 */
.chk_box3 {display: inline-block; vertical-align: top; position: relative;}
.chk_box3 input[type="checkbox"] + label {padding-left: 36px; font-size: 18px; font-weight: 500; color: #000; position: relative; line-height: 32px; display: block; cursor: pointer;}
.chk_box3 input[type="checkbox"] + label:before {content: ''; position: absolute; left: 0; top: 50%; width: 24px; height: 24px; border: 1px solid #ddd; background: transparent; box-sizing: border-box; border-radius: 100%; transition: all .2s; transform: translateY(-50%);}
.chk_box3 input[type="checkbox"] + label:after {content: ''; position: absolute; left: 8px; top: 8px; width: 5px; height: 10px; border: solid #fff; border-width: 0 3px 3px 0; transform: rotate(45deg); transition: all .2s;}
.chk_box3 input[type="checkbox"]:checked + label:before {border-color: var(--point-color1); background: var(--point-color1);}
.chk_box3 input[type="radio"]:checked + label:after {opacity: 1;}


/* 헤더 */
#header {position: fixed; left: 0; top: 0; width: 100%; z-index: 100;}
#header .header_inner {display: flex; justify-content: space-between; align-items: center; height: 180px; background: transparent; padding: 0 80px; z-index: 50;}
#header .logo {width: 100px; aspect-ratio: 5/3; background: url('../images/logo.svg') no-repeat 50% 50%/100%; text-indent: -9999px; text-align: left;}
#header .logo a {display: block; width: 100%; height: 100%;}
#header .gnb {display: flex; gap: 0 16px;}
#header .gnb li {width: 186px; }


/* 메인 */
@-webkit-keyframes mainVisualBgText {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
@-moz-keyframes mainVisualBgText {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes mainVisualBgText {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
.main_content {background: var(--point-color1); padding: 40px 40px 0; position: relative;}
.main_visual {height: 100vh; width: 100%; position: absolute; left: 0; top: 0; z-index: 10; overflow: hidden;}
.main_visual .cloud_img {position: absolute; left: 50%; bottom: 0; width: calc(100% - 80px); transform: translateX(-50%);z-index: 5; opacity: 0; border-radius: 40px;overflow: hidden;}
.main_visual .bg_txt {background: var(--point-color2); position: absolute; left: 50%; top: 40px; width: calc(100% - 80px); height: calc(100% - 40px); border-radius: 40px; overflow: hidden; z-index: 1; transform: translateX(-50%); overflow: hidden;}
.main_visual .bg_txt:after {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0; z-index: 2;}
.main_visual .bg_txt .txt {display: flex; margin-top: 50px; white-space: nowrap;}
.main_visual .bg_txt .txt:first-of-type {margin-top: 0;}
.main_visual .bg_txt .text {padding-left: 35px; display: flex; align-items: center; gap: 0 35px; font-size: 200px; font-weight: 400; color: #F1F3F9; font-family: "BagelFatOne", system-ui; line-height: 1; -webkit-animation: mainVisualBgText 30s linear infinite; -moz-animation: mainVisualBgText 30s linear infinite; animation: mainVisualBgText 30s linear infinite;}
.main_visual .bg_txt .ic {width: 224px; height: 180px;display: inline-block;background: url(../images/ic_heart01.png) no-repeat 50% 50%/100%;}
.main_visual .bg_txt .txt:nth-of-type(2n) .text {animation-direction: reverse;}
.main_visual .visual_box {position: absolute; left: 50%; top: 40px; transform: translateX(-50%); width: calc(100% - 80px); height: calc(100% - 40px); margin: 0 auto; z-index: 10; display: flex; justify-content: center; align-items: center;overflow: hidden;border-radius: 40px;}
.main_visual .visual_box .water_back_img { position: absolute; left: 50%; bottom: 0; width: 1413px; transform: translateX(-50%); z-index: -1; opacity: 0;max-width: none;}
.main_visual .visual_box .water_front_img {position: absolute; left: 50%; bottom: 0; width: 1413px; transform: translateX(-50%); z-index: 1; opacity: 0;max-width: none;}
.main_visual .visual_txt {position: relative;width: 800px;height: 680px;}
.main_visual .visual_txt .heart_img {position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 846px; z-index: -1;opacity: 0;transform: translate(-50%, -200%) scale(0);max-width: none;}
.main_visual .visual_txt .txt_img {position: relative; top: 30%; left: 50%; transform: translate(-50%, -70%); z-index: 20; transition: all .3s; opacity: 0;max-width: none;}
.btn_hover {position: absolute; left: 50%; bottom: 0;transform: translateX(-50%);width: 100%; max-width: 456px;z-index: 20; opacity: 0;}
.btn_hover button {width: 100%; height: 100%; position: relative;font-family: 'Cafe24Ssurround'; font-size: 28px; font-weight: 700;}
.btn_hover i {position: absolute; left: 0; top: 0; width: 100%; border-radius: 60px; line-height: 30px; padding: 25px 0; color: #fff; background: #000; border: 2px solid #000; transform: translateY(-12px); opacity: 1;}
.btn_hover i:before {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; box-shadow: 0px 12px 0px 0px rgba(0, 0, 0, 0.25); border-radius: 60px;}
.btn_hover b {display: block; border-radius: 60px; line-height: 30px; padding: 25px 0; color: var(--point-color1); background: #fff; border: 2px solid var(--point-color1); box-shadow: 0px 12px 0px 0px rgba(237, 36, 80, 0.16) inset; opacity: 0;}
@-webkit-keyframes mainVisualCenterText {
    0% {
        opacity: 0;
        transform: translate(-50%, -70%);
    }
    80% {
        transform: translate(-50%, -49%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
@-moz-keyframes mainVisualCenterText {
    0% {
        opacity: 0;
        transform: translate(-50%, -70%);
    }
    80% {
        transform: translate(-50%, -49%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
@keyframes mainVisualCenterText {
    0% {
        opacity: 0;
        transform: translate(-50%, -70%);
    }
    80% {
        transform: translate(-50%, -49%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
.main_visual .visual_txt .txt_img {-webkit-animation: mainVisualCenterText .7s .05s ease-out forwards; -moz-animation: mainVisualCenterText .7s .05s ease-out forwards; animation: mainVisualCenterText .7s .05s ease-out forwards;}
@-webkit-keyframes mainVisualHeart {
    0% {
        opacity: 0;
        transform: translate(-50%, -200%) scale(0);
    }
    70% {
        transform: translate(-50%, -45%) scale(1.04);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
@-moz-keyframes mainVisualHeart {
    0% {
        opacity: 0;
        transform: translate(-50%, -200%) scale(0);
    }
    70% {
        transform: translate(-50%, -45%) scale(1.04);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
@keyframes mainVisualHeart {
    0% {
        opacity: 0;
        transform: translate(-50%, -200%) scale(0);
    }
    70% {
        transform: translate(-50%, -45%) scale(1.04);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}
.main_visual .visual_txt .heart_img {-webkit-animation: mainVisualHeart 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards; -moz-animation: mainVisualHeart 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards; animation: mainVisualHeart 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards;}
@keyframes mainVisualWater {
    0% {
        opacity: 0;
        transform: translate(-50%, 0) scale(0);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, 0) scale(1.04);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0) scale(1);
    }
}
.main_visual .visual_box .water_back_img {-webkit-animation: mainVisualWater 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards; -moz-animation: mainVisualWater 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95)forwards;animation: mainVisualWater 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards;}
.main_visual .visual_box .water_front_img {-webkit-animation: mainVisualWater 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards; -moz-animation: mainVisualWater 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95)forwards;animation: mainVisualWater 1.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) forwards;}
@-webkit-keyframes mainVisualBtn {
    0% {
        opacity: 0;
        transform: translate(-50%, 100%);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -10%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
@-moz-keyframes mainVisualBtn {
    0% {
        opacity: 0;
        transform: translate(-50%, 100%);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -10%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
@keyframes mainVisualBtn {
    0% {
        opacity: 0;
        transform: translate(-50%, 100%);
    }
    70% {
        opacity: 1;
        transform: translate(-50%, -10%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
.btn_hover {-webkit-animation: mainVisualBtn .8s ease-in-out forwards; -moz-animation: mainVisualBtn .8s ease-in-out forwards; animation: mainVisualBtn .8s ease-in-out forwards;opacity: 0;transform: translate(-50%, 100%);}
@-webkit-keyframes mainVisualcloud {
    0% {
        opacity: 0;
        transform: translate(-50%, 50%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
@-moz-keyframes mainVisualcloud {
    0% {
        opacity: 0;
        transform: translate(-50%, 50%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
@keyframes mainVisualcloud {
    0% {
        opacity: 0;
        transform: translate(-50%, 50%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}
.main_visual .cloud_img {-webkit-animation: mainVisualcloud .5s .11s ease forwards; -moz-animation: mainVisualcloud .5s .11s ease forwards; animation: mainVisualcloud .5s .11s ease forwards;opacity: 0;transform: translate(-50%, 50%);}

/* 섹션 */
.main_content {position: relative;}
.main_content .section0 {height: calc(100vh - 40px);}
.main_content .section_wrap {position: relative;}
.main_content .section_wrap::after{position: absolute;content: '';width: calc(100% + 80px);height: 100%;background: url(../images/bg_main_line.svg) no-repeat 50% 50%;bottom: 0;left: -40px;}
.main_content .section_wrap .phone_sticky{position: relative;}
.main_content .phone_section {position: absolute; left: 0; top: 0; width: 100%; height: 100%; z-index: 5;}
.main_content .phone_section .phone_area {position: sticky; left: 0; top: 0; display: flex; justify-content: center; align-items: center; height: 100vh;}
.main_content .phone_section .phone_box {max-width: 350px; width: 100%; position: relative; border-radius: 28px; border: 1px solid #D3D7DB; background: #F1F4F9; max-height: 732px; height: calc(100% - 80px); font-size: 0;transition: all .5s;}
.main_content .phone_section .phone_box.on{border: 2px solid #FBBDCB;filter:drop-shadow(0px 0px 30px rgba(237,36,82,0.1)) drop-shadow(20px 20px 20px rgba(237,36,82,0.2));}
.main_content .phone_section .phone_box:before {content: ''; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: calc(100% - 24px); height: calc(100% - 24px); background: #fff; border-radius: 23px; border: 1px solid #ddd; box-sizing: border-box;}
.main_content .phone_section .phone_box:after {content: ''; position: absolute; left: 50%; top: 18px; width: 22px; height: 3px; transform: translateX(-50%); background: url('../images/img_phone_sub.svg') no-repeat 50% 50%/100%;}
.main_content .section {height: 100vh; width: 100%; position: relative;background: #fff;overflow-x: hidden;overflow-y: unset;}
.main_content .section .section_box {height: 100%; position: relative;}
.main_content .section .section_con {position: relative; height: 100%; display: flex; justify-content: center; align-items: center;}
.main_content .section .section_con .con_box {z-index: 10;opacity: 0;}
.main_content .section .section_con .tit_box{position: absolute;width: 40%;left: 8%;top: 12%;z-index: 10;}
.main_content .section .section_con .tit_box .num{font-family: 'LOTTERIACHAB';color: #F5F5F5;font-size: 120px;line-height: 1;}
.main_content .section .section_con .tit_box .tit{font-size: 40px;font-weight: 700;position: relative;padding-left: 26px;margin-top: -30px;}
.main_content .section .section_con .tit_box .tit::after{position: absolute;content: '';width: 18px;height: 18px;border-radius: 50%;background: var(--point-color1);top: 0;left: 0;}
.main_content .section .section_con .tit_box .tit strong{color: var(--point-color1);}
.main_content .section .section_con>.txt_box{font-size: 18px;position: absolute;width: 37%;left: 63%;opacity: 0;z-index: 10;}
.main_content .section.on .section_con>.txt_box{animation: aniLeft 1s .3s ease forwards;}

/* 섹션1 */
.main_content .section1 {border-radius: 40px 40px 0 0;overflow-x: unset;}
.main_content .section1 .top_pic {max-width: 1460px; width: 100%; margin: 0 auto; z-index: 20; position: absolute; left: 50%; top: 0; height: 100%; transform: translate(-50%, -10%);}
.main_content .section1 .list_top_pic {position: relative; width: 100%;}
.main_content .section1 .list_top_pic li {position: absolute; top: 0; width: calc((100% / 4) - 45px); transform: translateX(0); transition: all 1s; opacity: 1;}
.main_content .section1 .list_top_pic li:nth-of-type(2n) {transform: translate(0, 60px);}
.main_content .section1 .list_top_pic li:nth-of-type(1) {left: 0;}
.main_content .section1 .list_top_pic li:nth-of-type(2) {left: calc((100% / 4 - 45px) * 1 + (60px * 1));}
.main_content .section1 .list_top_pic li:nth-of-type(3) {left: calc((100% / 4 - 45px) * 2 + (60px * 2));}
.main_content .section1 .list_top_pic li:nth-of-type(4) {left: calc((100% / 4 - 45px) * 3 + (60px * 3));}
.main_content .section1 .section_con .con_box {max-width: 350px; width: 100%; position: relative; border-radius: 28px; max-height: 732px; height: calc(100% - 80px); font-size: 0;opacity: 1;}
.main_content .section1 .section_con .con_box:before {content: ''; position: absolute; left: 50%; right: auto; top: 50%; width: 131px; height: 112px; background: url('../images/ic_main1_1.png') no-repeat 50% 50%/100%; opacity: 0; transition: all 1s .3s; transform: translate(-50%, -50%);}
.main_content .section1 .section_con .con_box:after {content: ''; position: absolute; left: 50%; top: 50%; width: 217px; height: 186px; background: url('../images/ic_main1_2.png') no-repeat 50% 50%/100%; opacity: 0; transition: all 1s .3s; transform: translate(-50%, -50%);}
.main_content .section1 .phone_con {position: relative; height: 100%; width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 170px 0; padding: 190px 0 50px;}
.main_content .section1 .phone_con:before  {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%;}
.main_content .section1 .phone_con .img_box {transform: translateY(200%); opacity: 0; transition: 1s .3s;}
.main_content .section1 .phone_con .img_box span {display: block; text-align: center; font-size: 12px; font-weight: 600; color: var(--point-color1);}
.main_content .section1 .phone_con .txt_box {opacity: 0; transition: 1s .3s;}
.main_content .section1 .phone_con .txt_box span:before {content: ''; width: 71px; height: 67px; display: block; margin: 0 auto; background: url('../images/img_main1_2.png') no-repeat 50% 50%/100%;}
.main_content .section1 .phone_con .txt_box span {position: relative; display: block; text-align: center; font-size: 20px; font-weight: 500; color: var(--point-color3);}
.main_content .section1 .phone_con .txt_box span:after {content: ''; position: absolute; left: 50%; bottom: -8px; width: 40px; height: 40px; transform: translate(-50%, 100%); background: url('../images/ic_arrow01.svg') no-repeat 50% 50%/100%;}
.main_content .section1 .phone_con .txt_box span b {font-weight: 800;}
.main_content .section1.active .list_top_pic li {transform: translate(0, 0);}
.main_content .section1.on .top_pic {transform: translate(-50%, 0);}
.main_content .section1.on .list_top_pic {transform: translateY(0);}
.main_content .section1.on .list_top_pic li {left: 50%; top: 50%; transform: translate(-50%, 50%); opacity: 0;}
.main_content .section1.on .phone_con .img_box {transform: translateY(0); opacity: 1;}
.main_content .section1.on .phone_con .txt_box {opacity: 1;}
.main_content .section1.on .section_con .con_box:before {opacity: 1; right: -95px; left: auto; top: 12%; transform: translate(0, 0);}
.main_content .section1.on .section_con .con_box:after {opacity: 1; left: -177px; bottom: 10%; top: auto; transform: translate(0, 0);}

/* 섹션2 */
.main_content .section2.on .section_con .con_box {animation: aniUp 1s .3s ease forwards;}

/* 섹션3 */
.main_content .section3 .section_con .img_box{position: absolute;left:0;bottom: 20%;opacity: 0;z-index: 5;}
.main_content .section3.on .section_con .con_box {animation: aniUp 1s .3s ease forwards;}
.main_content .section3.on .section_con .img_box {animation: aniUp 1s .3s ease forwards;}

/* 섹션4 */
@keyframes mapImgShadow {
    0% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(0);
    }
    70% {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1);
    }
    100% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(1.5);
    }
}
.main_content .section4 .section_con {overflow-x: hidden;}
.main_content .section4 .section_con .con_box .map_img{position: relative;filter: drop-shadow(20px 40px 60px rgba(237, 36, 82, 0.08));}
.main_content .section4 .section_con .con_box .txt_img{position: absolute;opacity: 0;}
.main_content .section4 .section_con .con_box .txt_img1{top: 30px;right: -100px;}
.main_content .section4 .section_con .con_box .txt_img2{top: 120px;left: -65px;}
.main_content .section4 .section_con .con_box .txt_img3{top: 260px;right: -80px;}
.main_content .section4 .section_con .txt_box{bottom: 30%;}
.main_content .section4.on .section_con .con_box{animation: aniUp 1s .3s ease forwards;}
.main_content .section4.on .section_con .con_box .map_img::before{position: absolute;content: '';width: 500px;height: 500px;background: radial-gradient(circle, #FEEFF3 0%, rgba(247,249,252,0) 100%);top: 50%;left: 50%;transform: translate(-50%,-50%) scale(0);border-radius: 50%;animation: mapImgShadow 1.3s 1.5s linear infinite;z-index: -1;}
.main_content .section4.on .section_con .con_box .txt_img1{animation: aniUp 1s .8s ease forwards;}
.main_content .section4.on .section_con .con_box .txt_img2{animation: aniUp 1s 1.2s ease forwards;}
.main_content .section4.on .section_con .con_box .txt_img3{animation: aniUp 1s 1.5s ease forwards;}

/* 섹션5 */
.main_content .section5 {height: auto;min-height: 100vh;padding-bottom: 160px;}
.main_content .section5 .section_con {justify-content: flex-start;}
.main_content .section5 .section_con .con_box{margin:20% 0 0 200px;}
.main_content .section5.on .section_con .con_box{animation: aniUp 1s .3s ease forwards;}

/* 섹션6 */
.main_content .section6{padding-bottom: 160px;height: auto;min-height: 100vh;border-radius: 0 0 40px 40px;}
.main_content .section6 .section_con {justify-content: flex-end;}
.main_content .section6 .section_con .con_box{margin-right: 8%;}
.main_content .section6 .section_con .txt_box{left: 8%;}
.main_content .section6 .alarm_box{text-align: center;position: relative;padding:80px 0 132px;}
.main_content .section6 .alarm_box p{font-size: 36px;font-weight: 700;padding-top: 16px;}
.main_content .section6.on .section_con .con_box{animation: aniUp 1s .3s ease forwards;}

/* 섹션7 */
.main_content .section7{height: auto;padding: 140px 0 160px;background: #F1F3F9;border-radius: 40px;}
.main_content .section7 .section_con {justify-content: flex-end;}
.main_content .section7 .tit_box .tit2{font-size: 40px;font-weight: 700;padding-bottom: 48px;}
.main_content .section7 .tit_box .txt{font-size: 18px;color: #666;opacity: 0;}
.main_content .section7 .tit_box .txt2{font-size: 20px;padding-top: 20px;font-weight: 500;opacity: 0;}
.main_content .section7 .tit_box .txt2 span{color:var(--point-color3);}
.main_content .section7 .rule_box{margin-right: 8%;width: 800px;opacity: 0;}
.main_content .section7 .rule_box p{padding: 46px;text-align: center;background: #fff;border-radius: 40px;font-size: 20px;font-weight: 500;line-height: 1.5;}
.main_content .section7 .rule_box ul{background: #fff;border-radius: 40px;padding: 40px;position: relative;}
.main_content .section7 .rule_box ul::after{position: absolute;content: '';width: 80%;height: 2px; left: 50%;top: 0;transform: translateX(-50%);border-top: 2px dashed #ddd;}
.main_content .section7 .rule_box ul li{margin-right: 20px;}
.main_content .section7 .rule_box ul li:nth-child(2){transform: translate(-100%, 0);opacity: 0;}
.main_content .section7 .rule_box ul li:last-child{margin-right: 0;transform: translate(-200%, 0);}
.main_content .section7.on .tit_box .txt{animation: aniUp 1s .3s ease forwards;}
.main_content .section7.on .tit_box .txt2{animation: aniUp 1s .3s ease forwards;}
.main_content .section7.on .rule_box{animation: aniShow 1s .3s ease forwards;}
.main_content .section7.on .rule_box ul li:nth-child(2){animation: aniRuleImg 1s ease forwards;}
.main_content .section7.on .rule_box ul li:last-child{animation: aniRuleImg2 1s .2s ease forwards;}
@keyframes aniRuleImg {
    0% {
        opacity: 0;
        transform: translate(-100%, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
@keyframes aniRuleImg2 {
    0% {
        opacity: 0;
        transform: translate(-200%, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}


/* 푸터 */
#footer{padding: 0 40px 36px;background: #F1F4F9;}
#footer.main_foot{background: var(--point-color1);padding: 0 40px 83px;}
#footer .footer_cont{background: #000;border-radius: 40px;padding: 60px 40px;}
#footer .inner{max-width: 1400px;margin: 0 auto;position: relative;}
#footer .link_ul{position: absolute;right: 0;top: 0;}
#footer .link_ul li{margin-right: 60px;}
#footer .link_ul li:last-child{margin-right: 0;}
#footer .link_ul li a{font-size: 18px;font-weight: 600;color: #fff;}
#footer .copy{font-weight: 300;color: #fff;text-align: right;padding-bottom: 9px;line-height: 1.5;}
#footer .txt_box {padding-top: 40px;}
#footer .txt_box .txt{font-size: 24px;color: #fff;}
#footer .txt_box .txt_ul li{font-size: 20px;color: #fff;padding-right: 30px;position: relative;margin-top: 10px;}
#footer .txt_box .txt_ul li::after{position: absolute;content: '|';font-size: 22px;color: #fff;width: 30px;height: 100%;text-align: center;right: 0;top: 0;}
#footer .txt_box .txt_ul li:last-child{padding-right: 0;}
#footer .txt_box .txt_ul li:last-child::after{display: none;}
#footer .txt_box .number{color: #fff;font-size: 24px;font-weight: 700;}
.main_content #footer{padding: 0;background: none;}

/* 소개팅신청 */
.floating_wrap {position: fixed; right: 60px; bottom: 40px; z-index: 100;}
.floating_wrap .btn_love_request {display: block;}
.floating_wrap .btn_love_request:after {content: ''; position: absolute; right: -10px; bottom: -13px; width: 40px; height: 36px; background: url('../images/ic_request.svg') no-repeat 50% 50%/100%;}
.floating_wrap .btn_love_request strong {display: block; font-size: 24px; font-weight: 700; padding: 10px 30px; border: 2px solid var(--point-color1); background: linear-gradient(136deg, #FDA1B5 -1.2%, var(--point-color1) 65.73%); box-shadow: 0px 0px 20px 0px rgba(204, 217, 234, 0.60); border-radius: 40px; font-family: 'Cafe24Ssurround'; color: #fff;}
.floating_wrap .btn_love_request span {position: absolute; left: 12px; top: 0; line-height: 20px; padding: 10px 12px; border-radius: 40px; background: #000; box-shadow: 0px 0px 8px 0px rgba(237, 36, 80, 0.40); transform: translateY(-80%); font-size: 18px; font-weight: 700; color: #fff;}
.floating_wrap .btn_love_request span:before {content: ''; position: absolute; left: 50%; bottom: 0; border: 10px solid transparent; border-left-width: 6px; border-right-width: 6px; border-top-color: #000; transform: translate(-50%, 100%);}


/************************* popup *****************************/
.pop_wrap{position: fixed;width: 100%;height: 100dvh;top: 0;left: 0;z-index: 900;}
.pop_wrap .dim{position: absolute;width: 100%;height:100%;top: 0;left: 0;background: rgba(0,0,0,0.6);}
.pop_wrap .pop_cont{position: relative;width: 640px;border-radius: 20px;background:  #fff;overflow: hidden;}
.pop_wrap .pop_tit{border-bottom: 1px solid #999;position: relative;padding: 20px;text-align: center;}
.pop_wrap .pop_tit .tit{font-size: 28px;color: #000;font-weight: 700;padding: 0 60px;}
.pop_wrap .btn_close{position: absolute; width: 80px;height: 100%;font-size: 0;text-indent: -9999px;background: url(../images/ic_close.svg) no-repeat center left;top: 0;right: 0;}
.pop_wrap .pop_box{padding: 40px;}
.pop_wrap .bottom_btn_box button{width: calc(50% - 5px);}

/* 공통 컨펌팝업 */
.confirm_pop .pop_cont{width: 500px;}

/* 매니저상세 팝업 */
.manager_pop .dim{background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 40%, rgba(255, 225, 225, 0.8) 100%);backdrop-filter: blur(5px);}
.manager_pop .pop_cont{width: 100% !important;background: none;display: -webkit-box; display: -ms-flexbox; display: -webkit-flex; display: flex;-webkit-box-orient: vertical; -ms-flex-direction: column; flex-direction: column;-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;-webkit-box-pack: justify; -ms-flex-pack: justify;-webkit-justify-content: space-evenly; justify-content: space-evenly;height: 100dvh;}
.manager_pop .pop_box{padding: 0 !important;}
.manager_pop .pop_cont>.btn_close{font-size: 18px;font-weight: 500;text-indent: unset;height: 117px;background-position: center top 20px;padding-top: 88px;position: relative;}
.manager_pop .pop_cont>.btn_close::after{position: absolute;content: '';width: 80px;height: 80px;border: 1px solid #000;border-radius: 50%;top: 0;left: 0;}
.manager_pop .pop_tit{border-bottom: none;width: 960px;margin: 0 auto;padding: 0 0 40px;}
.manager_pop .pop_tit .tit{font-size: 40px;padding-left:26px;position: relative;}
.manager_pop .pop_tit .tit::after{position: absolute;content: '';width: 18px;height: 18px;background: var(--point-color1);border-radius: 50%;left: 0;top: 0;}
.manager_pop .pop_tit .tit strong{color: var(--point-color1);}
.manager_pop .pop_tit .btn_close{display: none;}
.manager_slider .swiper-slide{background: #fff;border-radius: 40px;padding: 20px 20px 40px;max-width:960px;}
.manager_slider .swiper-slide .img_box{width: 400px;height: 400px;border-radius: 40px;overflow: hidden;position: relative;}
.manager_slider .swiper-slide .img_box img{width: 100%;height: 100%;object-fit: cover;position: absolute;}
.manager_slider .swiper-slide .txt_box{width: calc(100% - 400px);padding: 40px 60px;height: 400px;overflow-x: hidden;overflow-y: auto;}
.manager_slider .swiper-slide .txt_box .name{font-size: 36px;font-weight: 700;}
.manager_slider .swiper-slide .txt_box .txt{font-size: 18px;padding-top: 40px;}
.manager_slider .swiper-slide .link_box{max-width: 600px;margin: 40px auto 0;}
.manager_slider .swiper-slide .link_box>div{width: calc(50% - 6px);}
.manager_pop .slider_box{position: relative;}
.manager_pop .slider_box .btn_box{position: absolute;width: 1300px;top: 40%;left: 50%;transform: translateX(-50%);z-index: 10;}
.manager_pop .slider_box .btn_box .swiper-button-prev{left: 0; padding-left: 32px;position: relative;width: auto;font-size: 18px;color: #000;font-weight: 500;}
.manager_pop .slider_box .btn_box .swiper-button-prev::after{position: absolute; content: '';left: 0;top: 0;width: 24px;height: 100%;background: url(../images/arrow_prev.svg) no-repeat center;background-size: 24px;}
.manager_pop .slider_box .btn_box .swiper-button-next{right: 0; padding-right: 32px;position: relative;width: auto;font-size: 18px;color: #000;font-weight: 500;}
.manager_pop .slider_box .btn_box .swiper-button-next::after{position: absolute; content: '';right: 0;top: 0;width: 24px;height: 100%;background: url(../images/arrow_next.svg) no-repeat center;background-size: 24px;}
.manager_pop .slider_box .btn_box>*.swiper-button-disabled{opacity: 0;}

/* 1:1문의 비밀글 비밀번호확인 팝업 */
.inquiry_auth_pop .pop_box .box {text-align: center;max-width: 480px;margin: 0 auto 40px;}
.inquiry_auth_pop .pop_box .box p{font-size: 20px;color: #666;padding-bottom: 24px;}
.inquiry_auth_pop .pop_box .box p span{color: #000;}

/* 소개팅 팝업 */
@keyframes hidePop {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}
@keyframes showPop {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.request_pop{animation: hidePop .3s .5s ease forwards;}
.request_pop .dim{opacity: 0;transition:all .3s .2s;}
.request_pop .pop_cont {position: absolute; left: 50%; top: 0; width: 50%; height: 100%; background: #fff; z-index: 1; padding: 60px 0; overflow: auto;border-radius: 0;transform: translateX(100%);transition:all .3s .2s;}
.request_pop h3 {text-align: center; padding-bottom: 40px;}
.request_pop h3 img {margin: 0 auto; display: block;}
.request_pop h3 strong {display: block; font-size: 36px; font-weight: 700; color: #000; padding-top: 10px;}
.request_pop .btn_close {position: absolute; right: 40px; top: 40px; font-size: 18px; font-weight: 500; color: #000; text-align: center; background: none; border-radius: 0; border: none; cursor: pointer;width: 80px;height: 80px;}
.request_pop .btn_close:before {content: ''; display: block; margin: 0 auto 8px; width: 80px; height: 80px; border-radius: 100%; border: 1px solid #ddd; background: url('../images/ic_pop_close.svg') no-repeat 50% 50%/24px;}
.form_wrap {max-width: 400px; width: 100%; margin: 0 auto;}
.form_wrap .form_ul>li{padding-bottom: 24px;}
.form_wrap .form_ul>li:last-child{padding-bottom: 0;}
.form_wrap .form_ul>li>h4{font-weight: 500;padding-bottom: 12px;color: #000;}
.request_pop .input_box input {background: #f8f8f8;}
.request_pop .form_wrap select {background: #f8f8f8;}
.request_pop .form_wrap h4 b {font-weight: 700; color: var(--point-color1);}
.request_pop .chk_wrap {padding: 25px 0;}
.request_pop .btn_wrap .btn_request {width: 100%; cursor: pointer; display: block; font-size: 20px; font-weight: 700; color: #fff; line-height: 30px; padding: 15px 0; border: none; border: 2px solid #000; border-radius: 60px; background: var(--point-color1); text-align: center; box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.25); transform: translateY(0);}
.request_pop .btn_wrap .btn_request:hover {box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.25) inset; transform: translateY(8px);}
.request_pop .input_wrap.flex_between > div{width: calc(50% - 5px);}
.request_pop.on {animation: showPop .3s ease forwards;opacity: 1;}
.request_pop.on .dim{opacity: 1;}
.request_pop.on .pop_cont{transform: translateX(0);}

/* 로딩팝업 */
.loading_pop{height: 100vh;}










