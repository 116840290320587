@charset "utf-8";
/* @import url("https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500,700,900&subset=korean"); */
/* 2021-09-02 likeweb.co.kr WebPublisher */

html, body {padding:0;margin:0;height:100%; color:#666; letter-spacing:-0.05em;}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, input,  blockquote {margin:0;padding:0;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main {display: block}
*:focus { outline:none; }
::-moz-selection{background:#0e559b;color:#fff;}
::selection{background:#0e559b;color:#fff;}
ol, ul, li {list-style:none;}
table {border-collapse:collapse;border-spacing:0px;}
fieldset, img,iframe{border:0; vertical-align:top;}
img{vertical-align:top; max-width:100%}
iframe{padding:0;margin:0}
input,radio {vertical-align:middle;}
a{color:#666; text-decoration:none; padding:0; margin:0;}
a:link    {text-decoration:none; }
a:active    {text-decoration:none; }
a:visited    {text-decoration:none;}
a:hover    {text-decoration:none; }
img {max-width: 100%;}
body {line-height: 1.5; -webkit-text-size-adjust:none;}
table {border-collapse: collapse; border-spacing: 0; width: 100%; table-layout: fixed; word-break: break-all;}
iframe {overflow: hidden; border: 0}
address,em,i {font-style: normal}
h1,h2,h3,h4,h5,h6 {font-weight: normal; font-size: 100%}
p {margin: 0;}
legend,.blind {position: absolute; overflow: hidden; clip: rect(0 0 0 0); margin: -1px; width: 1px; height: 1px}
caption {text-align: left; width: 1px; height: 1px; font-size:1px; line-height: normal; visibility: visible; clip: rect(0 0 0 0); overflow: hidden; color: transparent; display: none;}
textarea {
	width: 100%;
	border: 1px solid #ddd;
	background: none;
	box-sizing: border-box;
	resize: none;
}
textarea:focus {
	outline: none;
}
select {
	-webkit-appearance: none; 
	-moz-appearance: none;
	appearance: none; 
}

input::-ms-clear,input::-ms-reveal {
	display: none;
}
select::-ms-expand {display: none;} 
button {background: none; border: none; cursor: pointer;font-family:'Noto Sans KR','맑은 고딕', 'Malgun Gothic',sans-serif;padding: 0;}
body, td, select, textarea, input {font-size:16px; font-family:'Noto Sans KR','맑은 고딕', 'Malgun Gothic',sans-serif; color:#666; font-weight:400}