@charset "utf-8";

/* 서브헤더 */
#header .header_inner:not(.sub_header_inner) .btn_main_go {display: none;}
#header .header_inner.sub_header_inner {height: 96px; justify-content: center; box-shadow: 0px 8px 0px 0px #B51137; border-radius: 0px 0px 20px 20px; background: var(--point-color1);}
#header .header_inner.sub_header_inner .logo {background-image: url('../images/logo_sub.svg');width: 80px;}
#header .header_inner.sub_header_inner .btn_main_go {position: absolute; left: 80px; top: 50%; transform: translateY(-50%); width: 186px; border-radius: 60px; background: #fff; border: 2px solid #000; font-size: 20px; font-weight: 700; color: var(--point-color1); text-align: center; padding: 15px 0; box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.25);}
#header .header_inner.sub_header_inner .gnb_wrap {position: absolute; right: 80px; top: 50%; transform: translateY(-50%);}

/* 서브 공통 */
.sub_content {background: #F1F4F9; padding-top: 104px;}
.sub_content .section_inner {position: relative; max-width: 1400px; width: 100%; margin: 0 auto;}
.sub_content .cont{padding: 28px 0 100px;}
button {background: none; border: none; margin: 0; padding: 0; border-radius: 0; cursor: pointer;}
.sub_top_box{position: relative;}
.sub_top_box>img{position: absolute;right: 0;bottom: 0;}
.sub_top_box .tit_box{padding: 142px 0 60px;position: relative;}
.sub_top_box .tit_box .tit{font-size: 40px;font-weight: 700;position: relative;padding-left: 26px;}
.sub_top_box .tit_box .tit::after{position: absolute;content: '';width: 18px;height: 18px;background: var(--point-color1);border-radius: 50%;top: 0;left: 0;}
.sub_top_box .tit_box .txt{font-size: 20px;padding-top: 20px;}
.tab_box{border-bottom: 1px solid #ddd;overflow-y: hidden;overflow-x: auto;}
.tab_box ul{width: max-content;}
.tab_box li{margin-right: 60px;position: relative;}
.tab_box li:last-child{margin-right: 0;}
.tab_box li a{font-size: 24px;font-weight: 700;color: #999;padding-bottom: 8px;display: block;}
.tab_box li.on a{color: var(--point-color1);}
.tab_box li.on::after{position: absolute;content: '';width: 100%;height: 2px;bottom: 0;left: 0;background: var(--point-color1);}
.tab_box2{overflow-y: hidden;overflow-x: auto;}
.tab_box2 li{margin-right: 10px;}
.tab_box2 li button{padding: 0 20px;font-size: 18px;font-weight: 500;color: #999;height: 42px;border: 1px solid #ddd;border-radius: 50px;}
.tab_box2 li.on button{background: var(--point-color1);border: none;color: #fff;}
.search_area {max-width: 480px;width: 100%;}
.search_area .input_box {position: relative;}
.search_area .input_box input {display: block; border: 2px solid var(--point-color3); border-radius: 40px; padding: 0 60px 0 24px; background: #fff;}
.search_area .input_box input::-webkit-input-placeholder {color: #000; font-size: 18px; font-weight: 400; transition: all .2s;}/* WebKit, Blink, Edge */
.search_area .input_box input:-moz-placeholder {color: #000; font-size: 18px; font-weight: 400; opacity: 1; transition: all .2s;} /* Firefox 4 to 18 */
.search_area .input_box input::-moz-placeholder {color: #000; font-size: 18px; font-weight: 400; opacity: 1; transition: all .2s;} /* Firefox 19+ */
.search_area .input_box input:-ms-input-placeholder {color: #000 !important; font-size: 18px !important; font-weight: 400 !important; transition: all .2s !important;} /* Internet Explorer 10-11 */
.search_area .input_box input::-ms-input-placeholder {color: #000; font-size: 18px; font-weight: 400; transition: all .2s;} /* Microsoft Edge */
.search_area .input_box input::placeholder {color: #000; font-size: 18px; font-weight: 400; transition: all .2s;} /* Most modern browsers support this now */
.search_area .input_box .btn_search {position: absolute; right: 0; top: 0; aspect-ratio: 1 / 1; height: 100%; background: #000; z-index: 10; text-indent: -9999px; background: url('../images/ic_search01.png') no-repeat 40% 50%/24px;}
.none_data{padding: 80px 0;text-align: center;font-size: 18px;color: #666;border-bottom: 1px solid #ddd;}

/* 매니저 페이지 */
.page_manager {position: relative; overflow: hidden; padding-bottom: 200px;}
.page_manager:before {content: ''; aspect-ratio: 479 / 70; position: absolute; left: 50%; top: 40px; max-width: 1436px; width: 100%; transform: translateX(-50%); background: url('../images/bg_lovepong.png') no-repeat 50% 50%/100%;}
.page_manager .search_tit {padding: 80px 0 60px; display: flex; flex-direction: column; justify-content: center;}
.page_manager .search_tit h2 {display: inline-flex; justify-content: center; font-size: 40px; font-weight: 700; color: #000; gap: 10px; padding-bottom: 30px;}
.page_manager .search_tit h2:before {content: ''; width: 18px; height: 18px; border-radius: 100%; background: var(--point-color1);}
.page_manager .search_tit h2 b {color: var(--point-color1);}
.page_manager .search_area {margin: 0 auto;}
.list_manager_wrap .board_cnt {padding-bottom: 28px;}
.list_manager_wrap .board_cnt span {display: block; font-size: 20px; font-weight: 400; color: #1B171E;}
.list_manager_wrap .board_cnt span b {font-weight: 600; color: var(--point-color1);}
.list_manager {display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; gap: 40px;}
.list_manager li {position: relative; background: linear-gradient(180deg, rgba(254, 225, 232, 0.00) 28.12%, rgba(254, 225, 232, 0.49) 62.98%, #FEE1E8 100%); padding: 20px 10px 0; border-radius: 20px; overflow: hidden; transition: all .5s;cursor: pointer;}
.list_manager li:before {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; border-radius: 20px; overflow: hidden; background: linear-gradient(180deg, rgba(248, 92, 131, 0.00) 28.12%, rgba(248, 92, 131, 0.49) 62.98%, #E93864 100%); opacity: 0; transition: all .5s;}
.list_manager .img_box {position: relative;}
.list_manager .img_box:before {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: var(--point-color1); border-radius: 20px; opacity: 0; transition: all .3s;}
.list_manager .img {border-radius: 20px; overflow: hidden; position: relative; transform: translate(0, 0); transition: all .5s;height: 0;padding-bottom: 100%;}
.list_manager .img img {width: 100%;}
.list_manager .img:after {content: ''; position: absolute; left: 0; top: 0; width: 100%; height: 100%; border: 4px solid var(--point-color1); box-sizing: border-box; border-radius: 20px; opacity: 0; transition: all .5s;}
.list_manager .img:nth-of-type(2) {position: absolute; left: 0; top: 0; width: 100%; height: 100%; opacity: 0; transition: all .5s;}
.list_manager .txt {padding: 20px 14px 24px; position: relative;}
.list_manager .txt strong {display: inline-flex; gap: 0 12px; align-items: center; font-size: 36px; font-weight: 700; color: #000; transition: all .5s;width: 100%;}
.list_manager .txt strong:after {content: ''; flex: 0 0 48px; aspect-ratio: 1 / 1; background: url('../images/ic_arrow02.svg') no-repeat 50% 50%/100%; opacity: 0; transition: all .5s; filter: brightness(0);}
.list_manager .txt span {display: block; margin-top: 12px; font-size: 18px; font-weight: 500; color: #000; min-height: 56px; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; transition: all .5s;line-height: 1.5;}
.list_manager li.non {grid-column-end: span 4; background: none; padding: 100px 0 40px;}
.list_manager li.non:before {display: none;}
.list_manager li.non .ic img {display: block; margin: 0 auto;}
.list_manager li.non span {display: block; text-align: center; padding-top: 24px; font-size: 24px; font-weight: 500; color: #000;}

/* 고객센터 */
.list_cont{background: #fff;padding: 24px 60px 60px;border-radius: 40px;}
.list_top_box{padding-bottom: 20px;border-bottom: 1px solid #000;}
.list_top_box .tit{font-size: 20px;}
.list_top_box .tit strong{color: var(--point-color1);}
.list_top_box .search_area .input_box input{border: none;background: #F1F4F9;}
.list_top_box .search_area .input_box input::-webkit-input-placeholder {color: #999;}/* WebKit, Blink, Edge */
.list_top_box .search_area .input_box input:-moz-placeholder {color: #999;} /* Firefox 4 to 18 */
.list_top_box .search_area .input_box input::-moz-placeholder {color: #999;} /* Firefox 19+ */
.list_top_box .search_area .input_box input:-ms-input-placeholder {color: #999 !important;} /* Internet Explorer 10-11 */
.list_top_box .search_area .input_box input::-ms-input-placeholder {color: #999;} /* Microsoft Edge */
.list_top_box .search_area .input_box input::placeholder {color: #999;} /* Most modern browsers support this now */
.list_top_box .btn_type2{width: 148px;}
/* 공지사항 */
.list_type1 ul li{border-bottom: 1px solid #ddd;}
.list_type1 ul li>a{display: block;padding: 28px 0;color: #000;}
.list_type1 ul li .date_box{width: 15%;text-align: center;}
.list_type1 ul li .date_box .num{font-size: 28px;font-weight: 700;}
.list_type1 ul li .txt_box{width: 70%;padding-left: 32px;font-size: 18px;}
.list_type1 ul li .txt_box .tag{color: #fff;background: var(--point-color1);border-radius: 20px;font-weight: 700;width: 60px;line-height: 30px;text-align: center;margin-right: 16px;font-size: 16px;}
.list_type1 ul li .txt_box .tag+.ellipsis{width: calc(100% - 76px);}
.list_type1 ul li .name{width: 15%;text-align: center;font-weight: 500;}
.list_detail_cont{padding: 56px 60px 60px;background: #fff;border-radius: 40px;}
.list_detail_cont .top_box {padding: 0 20px 32px;border-bottom: 1px solid #ddd;}
.list_detail_cont .top_box .tit{font-size: 28px;font-weight: 700;width: calc(100% - 70px);}
.list_detail_cont .top_box .btn_ul li{margin-right: 12px;}
.list_detail_cont .top_box .btn_ul li:last-child{margin-right: 0;}
.list_detail_cont .top_box .btn_ul li button{display: block;width: 28px;height: 28px;background: url(../images/ic_share.svg) no-repeat 50% 50%/100%;font-size: 0;text-indent: -9999px;}
.list_detail_cont .top_box .btn_ul li:last-child button{background: url(../images/ic_list.svg) no-repeat 50% 50%/100%;}
.list_detail_cont .top_box .util_ul{padding-top: 20px;}
.list_detail_cont .top_box .util_ul li{position: relative;padding-right:33px;font-size: 18px;font-weight: 500;color: #999;}
.list_detail_cont .top_box .util_ul li::after{position: absolute;content: '';width: 1px;height: 80%;background: #ddd;top: 10%;right: 16px;}
.list_detail_cont .top_box .util_ul li:last-child{padding:0 0 0 32px;background: url(../images/ic_eye.svg) no-repeat 0 50%;}
.list_detail_cont .top_box .util_ul li:last-child::after{display: none;}
.list_detail_cont .cont_box{padding: 32px 24px;border-bottom: 1px solid #222;min-height: 300px;}
.list_detail_cont .btn_box{padding-top: 32px;text-align: center;}
.list_detail_cont .btn_box button{width: 240px;font-size: 16px;}
/* 1:1문의 */
.list_type2 ul li{border-bottom: 1px solid #ddd;}
.list_type2 ul li>button{padding: 40px 20px;width: 100%;}
.list_type2 ul li .status_txt{width: 10%;color: #999;font-weight: 500;font-size: 18px;}
.list_type2 ul li .status_txt.on{color: var(--point-color1);}
.list_type2 ul li .tit_box{width: calc(90% - 200px);text-align: left;padding-left: 30px;}
.list_type2 ul li .tit_box .ellipsis{width: auto;max-width: calc(100% - 36px);color: #000;}
.list_type2 ul li>button.on .tit_box .ellipsis{overflow: unset;white-space: normal;text-overflow: unset;line-height: 1.4;}
.list_type2 ul li .tit_box img{margin-left: 12px;}
.list_type2 ul li .date_box{width: 200px;}
.list_type2 ul li .date_box .name{color: #000;}
.list_type2 ul li .date_box .date{position: relative;color: #999;font-weight: 500;padding-left: 33px;}
.list_type2 ul li .date_box .date::after{position: absolute;content: '';width: 1px;height: 80%;background: #ddd;top: 10%;left: 17px;}
.list_type2 ul li .cont_box{padding: 28px 20px;background: #FAFAFD;border-top: 1px solid #ddd;}
.list_type2 ul li .cont_box>div{padding-left: calc(10% + 30px);}
.list_type2 ul li .cont_box .txt{font-size: 18px;color: #666;line-height: 1.5;}
.list_type2 ul li .cont_box .txt_box .txt{width: calc(100% - 120px);}
.list_type2 ul li .cont_box .txt_box .btn_box{width: 100px;}
.list_type2 ul li .cont_box .txt_box .btn_box>*{width: calc(50% - 2px);display: block;height: 34px;font-size: 14px;color: #666;text-align: center;font-weight: 600;line-height: 33px;border: 1px solid #ddd;border-radius: 5px;background: #fff;}
.list_type2 ul li .cont_box .txt_box .btn_box .btn_delt{background: #ECECEC;margin-left: 4px;}
.list_type2 ul li .cont_box .answer_box{border-top: 1px solid #ddd;padding-top: 24px;margin-top: 24px;}
.list_type2 ul li .cont_box .answer_box .tag{color: #fff;background: #000;border-radius: 5px;font-size: 14px;font-weight: 600;padding: 6px 12px;margin-right: 24px;}
.list_type2 ul li .cont_box .answer_box .name{font-size: 18px;color: #999;}
.list_type2 ul li .cont_box .answer_box .date{font-weight: 500;color: #999;padding-left: 33px;position: relative;}
.list_type2 ul li .cont_box .answer_box .date::after{position: absolute;content: '';width: 1px;height: 80%;top: 10%;left: 16px;background: #ddd;}
.list_type2 ul li .cont_box .answer_box .txt{padding-top: 12px;}
.list_write_cont{padding: 60px 0;background: #fff;border-radius: 40px;}
.list_write_cont .inner{max-width: 800px;margin: 0 auto;}
.list_write_cont .tit_box{text-align: center;padding-bottom: 60px;}
.list_write_cont .tit_box .tit{font-size: 36px;font-weight: 700;}
.list_write_cont .tit_box .txt{font-size: 18px;color: #666;padding-top: 16px;}
.list_write_cont .write_ul {border-top: 1px solid #000;}
.list_write_cont .write_ul li{padding: 16px 20px;border-bottom: 1px solid #ddd;}
.list_write_cont .write_ul li:nth-child(2) .txt{padding-top: 12px;}
.list_write_cont .write_ul li .txt{width: 20%;font-weight:500;}
.list_write_cont .write_ul li .txt span{color: var(--point-color1);}
.list_write_cont .write_ul li>div{width: 80%;}
.list_write_cont .write_ul li:nth-child(n+3)>div .input_box{max-width: 320px;}
.list_write_cont .btn_box{margin: 40px auto 0;max-width: 600px;}
.list_write_cont .btn_box button{width: calc(50% - 4px);}
/* FAQ */
.list_type3 li{border-bottom: 1px solid #ddd;}
.list_type3 li .tit_box{padding: 40px 0 40px 20px;}
.list_type3 li .tit_box .category{width: 10%;text-align: center;color: var(--point-color1);font-size: 18px;font-weight: 500;}
.list_type3 li .tit_box .tit{width: calc(90% - 48px);padding-left: 32px;font-size: 18px;font-weight: 500;}
.list_type3 li .tit_box .btn_toggle{width: 48px;height: 28px;}
.list_type3 li .cont_box{background: #FAFAFD;padding: 28px 20px;border-top: 1px solid #ddd;}
.list_type3 li .cont_box .txt{padding-left:calc(10% + 32px);font-size: 18px;color: #666;line-height: 1.5;}
.list_type3 li .tit_box.on .tit .ellipsis{overflow: unset;white-space: normal;text-overflow: unset;line-height: 1.4;}
.list_type3 li .tit_box.on .btn_toggle img{transform: rotate(180deg);}

/* 이용약관 */
.page_terms .terms_box{padding: 24px 60px 60px;font-size: 18px;background: #fff;border-radius: 40px;white-space: pre-line;}


