/* PC일때만 */
@media screen and (min-width: 1200px){
    /* 공통 */
    .btn_type1:hover,.btn_type2:hover {box-shadow: 0px 8px 0px 0px #333 inset; transform: translateY(8px);}
    .btn_type1:hover {box-shadow: 0px 8px 0px 0px rgba(0, 0, 0, 0.25) inset;}

    /* 메인 */
    .btn_hover:hover i {opacity: 0; transform: translateY(0)}
    .btn_hover:hover b {opacity: 1;}

    /* 서브헤더 */
    #header .header_inner.sub_header_inner .btn_main_go:hover {box-shadow: 0px 8px 0px 0px rgba(51, 51, 51, 0.3) inset; transform: translateY(calc(-50% + 8px));}

    /* 매니저 페이지 */
    .list_manager li:hover:before {opacity: 1;}
    .list_manager li:hover .img_box:before {opacity: 1;}
    .list_manager li:hover .img {transform: translate(-10px, -10px);}
    .list_manager li:hover .img:nth-of-type(2) {opacity: 1;}
    .list_manager li:hover .img:after {opacity: 1;}
    .list_manager li:hover .txt strong {color: #fff;}
    .list_manager li:hover .txt span {color: #fff;}
    .list_manager li:hover .txt strong:after {opacity: 1; filter: brightness(1);}
}


@media screen and (max-width: 1600px){
    /* 메인 */
    .main_content .section .section_con .tit_box{left: 40px;}
    .main_content .section6 .section_con .txt_box{left: 40px;}
    .main_content .section7 .rule_box{margin-right: 40px;}

}


@media screen and (max-height: 770px){
    /* 매니저팝업 */
    .manager_pop .pop_cont>.btn_close{display: none;}
    .manager_pop .pop_tit .btn_close{display: block;background-position: center top;}
}


@media screen and (max-width: 1410px){
    .sub_content .section_inner{padding: 0 20px;}
}


/********************************** 태블릿 **********************************/
@media screen and (max-width: 1317px){
    /* 헤더 */
    #header .header_inner {padding: 0 40px; height: 112px;}
    #header .gnb li a {padding: 9px 0;}

    /* 메인 */
    .main_content{padding: 20px 20px 0;}
    .main_visual .cloud_img{width: calc(100% - 40px);}
    .main_visual .bg_txt{width: calc(100% - 40px);height: calc(100% - 20px); top: 20px;}
    .main_visual .visual_box{width: calc(100% - 40px);height: calc(100% - 20px);top: 20px;}
    .main_content .section_wrap::after{display: none;}
    .main_content .phone_section .phone_box{max-width: 300px;height: 636px;max-height:none;}
    .main_content .phone_section .phone_box.on{border: 1px solid #D3D7DB;filter: none;}
    .main_content .section0{height: calc(100vh - 20px);}
    .main_content .section{height: auto;min-height: 100vh;padding-bottom: 100px;}
    .main_content .section .section_con{-webkit-box-orient: vertical; -ms-flex-direction: column; flex-direction: column;}
    .main_content .section .section_con .tit_box{position: static;width: 100%;text-align: center;padding-bottom: 30px;}
    .main_content .section .section_con .tit_box .tit{display: inline-block;}
    .main_content .section .section_con>.txt_box{position: static;width: 100%;text-align: center;padding-top: 66px;}
    .main_content .section1 .section_con .con_box{max-width: 300px;height: 636px;max-height: none;}
    .main_content .section2 .section_con .con_box img{height: 612px;}
    .main_content .section3 .section_con .con_box img{height: 526px;padding-top: 70px;}
    .main_content .section3 .section_con .img_box{display: none;}
    .main_content .section4 .section_con .con_box .txt_img1,.main_content .section4 .section_con .con_box .txt_img3{right: -90px;}
    .main_content .section5 .section_con .con_box{margin: 0;}
    .main_content .section6 .section_con .con_box{margin: 0;}
    .main_content .section7{padding: 120px 0;}
    .main_content .section7 .rule_box{margin:60px auto 0;width: calc(100% - 80px);}

    /* 서브 공통 */
    .sub_content {padding-top: 92px;}
    .sub_content .section_inner {padding: 0 20px;}
    .sub_top_box>img{height: 220px;}
    .sub_top_box .tit_box{padding-top: 80px;}

    /* 서브헤더 */
    #header .header_inner.sub_header_inner {justify-content: space-between; padding: 0 20px; height: 84px;}
    #header .header_inner.sub_header_inner .btn_main_go {display: none;}
    #header .header_inner.sub_header_inner .gnb_wrap {position: static; transform: translate(0);}

    /* 매니저 페이지 */
    .page_manager {padding-bottom: 160px;}
    .page_manager:before {width: calc(100% + 16px);}
    .list_manager_wrap .board_cnt {padding-bottom: 20px;}
    .list_manager {grid-template-columns: 1fr 1fr; gap: 40px 28px;}
    .list_manager li {padding: 20px 25px 0;}
    .list_manager li:hover .img {transform: translate(0, 0);}
    .list_manager .txt {padding: 20px 0;}

    /* 공지사항 */
    .list_cont{padding: 24px 40px 60px;}
    .list_detail_cont{padding: 56px 40px 60px;}
    .list_detail_cont .cont_box{padding: 32px 0;}

    /* 1:1문의 */
    .list_type2 ul li .cont_box>div{padding: 0;}
    .list_write_cont{padding: 60px 40px;}

    /* FAQ */
    .page_service .list_top_box .tab_box2{width: 100%;margin-top: 16px;}
    .list_type3 li .cont_box .txt{padding: 0;}

    /* 이용약관 */
    .page_terms .terms_box{padding: 24px 40px 40px;}

    /* 푸터 */
    #footer{padding: 0 20px 20px;}
    #footer.main_foot{padding: 0 20px 20px;}
    #footer .link_ul{position: static;}
    #footer .logo_box{-webkit-box-align: center; -ms-flex-align: center;-webkit-align-items: center; align-items: center;padding-top: 20px;}
    #footer .txt_box{display: block;}
    #footer .txt_box>div:nth-child(2){padding-top: 20px;}

    /* 소개팅신청 */
    .floating_wrap {right: 20px; bottom: 20px;}

    /* 매니저팝업 */
    .manager_pop .pop_cont{display: block;background: #fff;border-radius: 0;padding: 40px 0 0;}
    .manager_pop .pop_cont>.btn_close{display: none;}
    .manager_pop .pop_box{height: 100%;}
    .manager_pop .pop_tit{width: 100%;position: static;padding: 0 84px 20px;}
    .manager_pop .pop_tit .btn_close{width: 80px;height: 80px;background-position: left bottom;display: block;}
    .manager_pop .pop_cont .slider_box{height: calc(100% - 72px);padding: 0 84px;}
    .manager_slider{height: 100%;}
    .manager_slider .swiper-slide{padding: 0;max-width: none;}
    .manager_slider .swiper-slide .box{display: block;height: calc(100% - 100px);overflow-x: hidden;overflow-y: auto;}
    .manager_slider .swiper-slide .img_box{width: 100%;height: 0;padding-bottom: 100%;}
    .manager_slider .swiper-slide .txt_box{width: 100%;height: auto;padding: 40px 0 0;}
    .manager_slider .swiper-slide .link_box{margin: 20px auto 0;}
    .manager_pop .slider_box .btn_box{width: 100%;}
    .manager_pop .slider_box .btn_box .swiper-button-prev span,.manager_pop .slider_box .btn_box .swiper-button-next span{display: none;}
    .manager_pop .slider_box .btn_box .swiper-button-prev,.manager_pop .slider_box .btn_box .swiper-button-next {width: 60px;height: 60px;padding: 0;}
    .manager_pop .slider_box .btn_box .swiper-button-prev::after,.manager_pop .slider_box .btn_box .swiper-button-next::after{width: 60px;background-size: 60px;}

    /* 소개팅 팝업 */
    .request_pop .pop_cont {left: 0; width: 100% !important; padding: 60px 20px;}
    .request_pop .btn_close {font-size: 0; padding: 0;}
    .request_pop .btn_close:before {width: 40px; height: 40px; border: none; margin: 0 auto;}
}


@media screen and (max-width: 1000px){
    /* 메인 */
    .main_content .section1 .list_top_pic li{width: 50%;text-align: center;}
    .main_content .section1 .list_top_pic li:nth-of-type(2){left: 50%;}
    .main_content .section1 .list_top_pic li:nth-of-type(n+3){display: none;}
    .main_content .section1 .list_top_pic li img{max-height: 612px;}
}


/********************************** 모바일 **********************************/
@media screen and (max-width: 767px){
    /* 공통 */
    .mo_show{display: block !important;}
    .mo_none{display: none !important;}
    .btn_type1 a, .btn_type2 a{line-height: 20px;font-size: 18px;}
    /* 체크박스 */
    .chk_box3 input[type="checkbox"] + label {font-size: 16px;}

    /* 헤더 */
    #header .header_inner {padding: 0 16px; height: 98px;}
    #header .logo {display: none;}
    #header .gnb_wrap {flex: 1;}
    #header .gnb {gap: 0 8px;}
    #header .gnb li {flex: 1 1 0; width: auto; box-shadow: 0px 6px 0px 0px rgba(0, 0, 0, 0.25);}
    #header .gnb li a {padding: 8px 0; font-size: 18px;}

    /* 메인 */
    .main_visual .bg_txt{border-radius: 20px;width: calc(100% - 20px);height: calc(100% - 12px);top: 12px;}
    .main_visual .bg_txt .txt:first-of-type{margin-top: 60px;}
    .main_visual .bg_txt .text{font-size: 125px;}
    .main_visual .bg_txt .ic{width: 140px;height: 113px;}
    .main_visual .visual_box{border-radius: 20px;width: calc(100% - 20px);height: calc(100% - 12px);top: 12px;}
    .main_visual .visual_box .water_back_img{width: 518px;bottom: 60px;}
    .main_visual .visual_box .water_front_img{width: 518px;bottom: 60px;}
    .main_visual .visual_txt{width: 100%;height: 100%;}
    .main_visual .visual_txt .heart_img{width: 497px;}
    .main_visual .visual_txt .txt_img{top: 40%;width: 460px;}
    .main_visual .visual_box .btn_hover{bottom: 40px;}
    .main_visual .cloud_img{width: calc(100% - 20px);border-radius: 10px;}
    .main_content{padding: 12px 10px 0;}
    .main_content .phone_section .phone_box{max-width: 216px;height: 460px;border-radius: 20px;}
    .main_content .phone_section .phone_box:before{width: calc(100% - 12px);height: calc(100% - 20px);border-radius: 16px;}
    .main_content .section .section_con .tit_box{padding: 0 20px 30px;}
    .main_content .section .section_con .tit_box .num{font-size: 80px;}
    .main_content .section .section_con .tit_box .tit{font-size: 24px;padding-left: 20px;}
    .main_content .section .section_con .tit_box .tit::after{width: 12px;height: 12px;}
    .main_content .section .section_con>.txt_box{font-size: 16px;padding-right: 12px;padding-left: 12px;}
    .main_content .section1{border-radius: 20px 20px 0 0;}
    .main_content .section1 .top_pic{transform: translate(-50%,-5%);}
    .main_content .section1 .list_top_pic li{padding: 0 5px;}
    .main_content .section1 .section_con .con_box{max-width: 216px;height: 460px;}
    .main_content .section1 .section_con .con_box:before{width: 79px;height: 68px;}
    .main_content .section1 .section_con .con_box:after{width: 101px;height: 86px;}
    .main_content .section1 .phone_con{gap: 30px 0;padding-top: 280px;}
    .main_content .section1 .phone_con .img_box{width: 146px;}
    .main_content .section1 .phone_con .img_box span{font-size: 16px;}
    .main_content .section1 .phone_con .txt_box span:before{width: 52px;height: 48px;}
    .main_content .section1 .phone_con .txt_box span{font-size: 16px;}
    .main_content .section1.on .section_con .con_box:before{right: -40px;top: 30%;}
    .main_content .section1.on .section_con .con_box:after{left: -40px;}
    .main_content .section2 .section_con .con_box img{height: 440px;}
    .main_content .section3 .section_con .con_box img{height: 380px;}
    .main_content .section4 .section_con .con_box .map_img img{height: 420px;}
    .main_content .section4 .section_con .con_box .txt_img1{right: -30px;}
    .main_content .section4 .section_con .con_box .txt_img1 img{height: 58px;}
    .main_content .section4 .section_con .con_box .txt_img2{top: 110px;left: -30px;}
    .main_content .section4 .section_con .con_box .txt_img2 img{height: 82px;}
    .main_content .section4 .section_con .con_box .txt_img3{right: -30px;top: 210px;}
    .main_content .section4 .section_con .con_box .txt_img3 img{height: 58px;}
    .main_content .section4.on .section_con .con_box .map_img::before{width: 300px;height: 300px;}
    .main_content .section6{border-radius: 0 0 20px 20px;}
    .main_content .section6 .alarm_box{padding: 60px 10px 80px;}
    .main_content .section6 .alarm_box>img{width: 92px;}
    .main_content .section6 .alarm_box p{padding: 12px 0 20px;font-size: 20px;}
    .main_content .section7{border-radius: 20px;padding: 80px 0;}
    .main_content .section7 .tit_box .tit2{font-size: 24px;padding-bottom: 28px;}
    .main_content .section7 .tit_box .txt{font-size: 16px;}
    .main_content .section7 .tit_box .txt2{font-size: 18px;}
    .main_content .section7 .rule_box{margin: 40px auto 0;width: calc(100% - 40px);}
    .main_content .section7 .rule_box p{font-size: 16px;padding: 38px 30px;}
    .main_content .section7 .rule_box ul{-webkit-box-orient: vertical; -ms-flex-direction: column; flex-direction: column;padding: 40px 20px;}
    .main_content .section7 .rule_box ul li{margin:0 0 20px;}
    .main_content .section7 .rule_box ul li:last-child{margin-bottom: 0;}
    .btn_hover{width: calc(100% - 20px);}
    .btn_hover button{font-size: 20px;}
    .btn_hover i{line-height: 20px;padding: 20px 0;}
    .btn_hover b{line-height: 20px;padding: 20px 0;}

    /* 서브 공통 */
    .sub_content {padding-top: 136px;}
    .sub_content .section_inner {padding: 0 10px;}
    .sub_content .cont{padding: 20px 0 60px;}
    .sub_top_box{padding: 0 10px;}
    .sub_top_box .tit_box{padding: 60px 0 30px;}
    .sub_top_box .tit_box .tit{font-size: 24px;padding-left: 20px;}
    .sub_top_box .tit_box .tit::after{width: 12px;height: 12px;}
    .sub_top_box .tit_box .txt{font-size: 18px;padding-top: 4px;}
    .tab_box li{margin-right: 40px;}
    .tab_box li a{font-size: 18px;}
    .list_cont{border-radius: 20px;padding: 20px 20px 40px;}
    .list_top_box{padding-bottom: 16px;}
    .list_top_box .tit{padding-bottom: 8px;}
    .tab_box2 li{margin-right: 8px;}
    .tab_box2 li button{font-size: 16px;height: 35px;}
    .none_data{font-size: 16px;}

    /* 서브헤더 */
    #header .header_inner.sub_header_inner {display: block; height: auto; padding: 16px 20px;}
    #header .header_inner.sub_header_inner .logo {display: block; margin: 0 auto; width: 48px;}
    #header .header_inner.sub_header_inner .gnb_wrap {padding-top: 10px;}

    /* 매니저 페이지 */
    .page_manager {padding-bottom: 120px;}
    .page_manager:before {top: 30px;}
    .page_manager .search_tit {padding: 40px 0;}
    .page_manager .search_tit h2 {font-size: 24px; gap: 0 6px;}
    .page_manager .search_tit h2:before {width: 12px; height: 12px;}
    .page_manager .search_area .input_box input {height: 44px;}
    .page_manager .search_area .input_box input::-webkit-input-placeholder {color: #000; font-size: 16px; font-weight: 400; transition: all .2s;}/* WebKit, Blink, Edge */
    .page_manager .search_area .input_box input:-moz-placeholder {color: #000; font-size: 16px; font-weight: 400; opacity: 1; transition: all .2s;} /* Firefox 4 to 18 */
    .page_manager .search_area .input_box input::-moz-placeholder {color: #000; font-size: 16px; font-weight: 400; opacity: 1; transition: all .2s;} /* Firefox 19+ */
    .page_manager .search_area .input_box input:-ms-input-placeholder {color: #000 !important; font-size: 16px !important; font-weight: 400 !important; transition: all .2s !important;} /* Internet Explorer 10-11 */
    .page_manager .search_area .input_box input::-ms-input-placeholder {color: #000; font-size: 16px; font-weight: 400; transition: all .2s;} /* Microsoft Edge */
    .page_manager .search_area .input_box input::placeholder {color: #000; font-size: 16px; font-weight: 400; transition: all .2s;} /* Most modern browsers support this now */
    .page_manager .search_area .input_box .btn_search {background-size: 20px; background-position-x: 30%;}
    .list_manager_wrap .board_cnt {padding-bottom: 40px;}
    .list_manager_wrap .board_cnt span {font-size: 16px;}
    .list_manager {gap: 24px 8px;}
    .list_manager li {padding: 0 3px;}
    .list_manager .txt {padding: 10px 5px;}
    .list_manager .txt strong {font-size: 20px; gap: 0 5px;}
    .list_manager .txt strong:after {flex: 0 0 30px;}
    .list_manager .txt span {margin-top: 4px; font-size: 14px; min-height: 42px;}
    .list_manager li.non {padding: 80px 0;}
    .list_manager li.non span {font-size: 18px; padding-top: 16px;}

    /* 공지사항 */
    .list_type1 ul li>a{padding: 16px 0;}
    .list_type1 ul li .date_box{width: 100%;text-align: left;font-size: 14px;font-weight: 600;}
    .list_type1 ul li .date_box .num{display: none;}
    .list_type1 ul li .txt_box{width: 100%;padding: 8px 0 0 0;}
    .list_type1 ul li .txt_box .tag{height: 25px;font-size: 14px;line-height: 25px;width: 48px;margin-right: 8px;}
    .list_type1 ul li .txt_box .tag + .ellipsis{width: calc(100% - 56px);}
    .list_type1 ul li .name{display: none;}
    .list_detail_cont{padding: 20px 20px 40px;border-radius: 20px;}
    .list_detail_cont .top_box{padding: 0 0 20px;}
    .list_detail_cont .tit_box{position: relative;padding-top: 32px;}
    .list_detail_cont .tit_box .btn_ul{position: absolute;top: 0;right: 0;}
    .list_detail_cont .top_box .btn_ul li button{width: 20px;height: 20px;}
    .list_detail_cont .top_box .tit{font-size: 20px;width: 100%;}
    .list_detail_cont .top_box .util_ul{padding-top: 12px;}
    .list_detail_cont .top_box .util_ul li{font-size: 16px;padding-right: 25px;}
    .list_detail_cont .top_box .util_ul li::after{right: 13px;}
    .list_detail_cont .btn_box{padding-top: 20px;}

    /* 1:1문의 */
    .list_type2 ul li>button{padding: 16px 0;}
    .list_type2 ul li .status_txt{width: 100%;text-align: left;font-size: 16px;}
    .list_type2 ul li .tit_box{width: 100%;padding: 8px 0 16px;}
    .list_type2 ul li .date_box{width: 100%;-webkit-box-pack: start; -ms-flex-pack: start;-webkit-justify-content: flex-start; justify-content: flex-start;}
    .list_type2 ul li .date_box .date{padding-left: 25px;}
    .list_type2 ul li .date_box .date::after{left: 12px;}
    .list_type2 ul li .cont_box{padding: 16px 12px;}
    .list_type2 ul li .cont_box .txt{font-size: 16px;}
    .list_type2 ul li .cont_box .txt_box {-ms-flex-wrap: wrap;flex-wrap: wrap;-webkit-box-pack: end; -ms-flex-pack: end;-webkit-justify-content: flex-end; justify-content: flex-end;}
    .list_type2 ul li .cont_box .txt_box .txt{width: 100%;padding-bottom: 12px;}
    .list_type2 ul li .cont_box .answer_box .tag{margin-right: 16px;}
    .list_type2 ul li .cont_box .answer_box .name{font-size: 16px;}
    .list_type2 ul li .cont_box .answer_box .date{padding-left: 25px;}
    .list_type2 ul li .cont_box .answer_box .date::after{left: 12px;}
    .list_write_cont{padding: 40px 20px;border-radius: 20px;}
    .list_write_cont .tit_box .tit{font-size: 24px;}
    .list_write_cont .write_ul li{padding: 16px 0;}
    .list_write_cont .write_ul li .txt{width: 100%;padding-bottom: 8px;}
    .list_write_cont .write_ul li>div{width: 100%;}

    /* FAQ */
    .page_service .list_top_box .tab_box2{margin-top: 0;}
    .list_type3 li .tit_box{padding: 16px 0;}
    .list_type3 li .tit_box .category{width: 100%;font-size: 16px;text-align: left;padding-bottom: 8px;}
    .list_type3 li .tit_box .tit{width: calc(100% - 28px);padding-left: 0;}
    .list_type3 li .tit_box .btn_toggle{width: 28px;}
    .list_type3 li .cont_box{padding: 16px 12px;}
    .list_type3 li .cont_box .txt{font-size: 16px;}

    /* 이용약관 */
    .page_terms .terms_box{padding: 20px;border-radius: 20px;}


    /* 푸터 */
    #footer {padding: 0 10px 10px;}
    #footer.main_foot{padding: 0 10px 12px;}
    #footer .footer_cont{padding: 40px 20px;border-radius: 20px;}
    #footer .link_ul li{margin-bottom: 16px;}
    #footer .link_ul li a{font-size: 16px;}
    #footer .logo_box{padding-top: 8px;}
    #footer .logo_box>img{height: 80px;}
    #footer .copy{font-size: 12px;}
    #footer .txt_box{padding-top: 24px;}
    #footer .txt_box .txt{font-size: 16px;}
    #footer .txt_box .txt_ul li{font-size: 14px;margin-top: 10px;padding-right: 0;}
    #footer .txt_box .txt_ul li::after{display: none;}
    #footer .txt_box>div:nth-child(2){padding-top: 16px;}
    #footer .txt_box .number{font-size: 20px;}

    /* 소개팅신청 */
    .floating_wrap .btn_love_request strong {font-size: 18px; padding: 5px 20px;}
    .floating_wrap .btn_love_request span {left: 2px; font-size: 14px; padding: 3px 12px;}
    .floating_wrap .btn_love_request:after {right: -4px; bottom: -7px; width: 22px; height: 19px;}

    /* 팝업 */
    .pop_wrap .pop_cont{width: calc(100% - 20px);}
    .pop_wrap .pop_tit {padding: 16px;}
    .pop_wrap .pop_tit .tit{font-size: 20px;padding: 0 30px;}
    .pop_wrap .btn_close{width: 40px;background-size: 28px;}
    .pop_wrap .pop_box{padding: 20px 12px;}

    /* 매니저팝업 */
    .manager_pop .pop_cont{padding: 33px 0 0;}
    .manager_pop .pop_tit{padding: 0 20px 12px;-webkit-box-pack: center; -ms-flex-pack: center;-webkit-justify-content: center; justify-content: center;}
    .manager_pop .pop_tit .tit{padding:0 0 0 20px;font-size: 24px;}
    .manager_pop .pop_tit .tit::after{width: 12px;height: 12px;}
    .manager_pop .pop_tit .btn_close{width: 50px;height: 50px;background-size: 28px;}
    .manager_pop .pop_cont .slider_box{height: calc(100% - 43px);padding: 0 16px;}
    .manager_slider .swiper-slide .box{height: calc(100% - 124px);}
    .manager_slider .swiper-slide .img_box{border-radius: 20px;}
    .manager_slider .swiper-slide .txt_box{padding-top: 20px;}
    .manager_slider .swiper-slide .txt_box .name{font-size: 24px;}
    .manager_slider .swiper-slide .txt_box .txt{font-size: 14px;padding-top: 20px;}
    .manager_slider .swiper-slide .link_box{margin-top: 56px;}
    .manager_pop .slider_box .btn_box{top: auto;bottom: 84px;}
    .manager_pop .slider_box .btn_box .swiper-button-prev, .manager_pop .slider_box .btn_box .swiper-button-next{width: 40px;height: 40px;}
    .manager_pop .slider_box .btn_box .swiper-button-prev::after, .manager_pop .slider_box .btn_box .swiper-button-next::after{width: 40px;background-size: 40px;}

    /* 소개팅 팝업 */
    .request_pop h3 img {max-width: 140px;}
    .request_pop h3 strong {font-size: 28px;}
    .request_pop .btn_close {right: 20px; top: 20px;width: 28px; height: 28px;}
    .request_pop .btn_close:before {width: 28px; height: 28px;background-size: 16px;}
    .request_pop .chk_wrap {padding: 20px 0;}
}